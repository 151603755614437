import { 
    createMuiTheme,
    responsiveFontSizes
} from '@material-ui/core';

let theme = createMuiTheme({
    palette: {
        primary: {
            main: "#A73BF7",
        },
        secondary: {
            main: "#58C408",
        },
    },
    typography: {
        fontFamily: "Raleway",
        button: {
            textTransform: "none"
        }
    }
});

theme = responsiveFontSizes(theme);

export default theme;
