import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import PropTypes from 'prop-types';
import L from 'leaflet';
import marker from "../../assets/marker.png"

const iconMarker = L.icon({
    iconUrl: marker,
    iconSize: [42, 60],
})

function Map(props) {
    const center = [-34.766187, -58.400001];

    const {
        renderPopup, markers, markerTouch, renderTooltip,
        markerOpacity, ...otherProps
    } = props;

    const renderMarkerPopup = (marker) => {
        if (renderPopup === undefined) {
            return null;
        }

        return renderPopup(marker);
    }

    const renderMarkerTooltip = (marker) => {
        if (renderTooltip === undefined) {
            return null;
        }

        return renderTooltip(marker);
    }

    const markerTouched = (marker) => {
        if (markerTouch !== undefined) {
            markerTouch(marker);
        }
    }

    return (
        <MapContainer {...otherProps} center={center} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                attribution='<a href="https://www.google.es/maps/preview">Google Maps</a>'
                url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
            />
            {markers.map((marker, idx) => 
                <Marker
                    key={idx}
                    position={
                        [marker["location"]["latitude"],
                        marker["location"]["longitude"]]
                    }
                    eventHandlers={{
                        click: () => {
                            markerTouched(marker)
                        },
                    }}
                    icon={iconMarker}
                >
                    {renderMarkerPopup(marker)}
                    {renderMarkerTooltip(marker)}
                </Marker>
            )}
        </MapContainer>
    );
};

Map.propTypes = {
    markers: PropTypes.array.isRequired
}

export default Map;
