import React, { useState, useEffect } from 'react';
import {
    Typography,
    Link
} from '@material-ui/core';
import useStyles from './styles'
import { Link as RouterLink, withRouter }  from 'react-router-dom'

function PolicyScreen() {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Política de privacidad
                </Typography>
                <Typography variant="body1">
                    El Colegio de Farmacéuticos de Lomas de Zamora desarrolló Lomas Farma como una aplicación gratuita.
                    Este Servicio es provisto por el Colegio de Farmacéuticos de Lomas de Zamora
                    sin costo y está diseñado para utilizarse como es.
                    <br/>
                    Esta pagina es utilizada para informar a nuestros visitantes con respecto a nuestras políticas
                    para la recolección, uso y divulgación de información personal.
                    <br/>
                    Si elige utilizar nuestro Servicio, entonces acepta la recopilación
                    y el uso de información en relación con esta política de privacidad. 
                    La información personal que recopilamos se utiliza para poder proporcionar
                    y mejorar nuestro servicio. No usaremos ni compartiremos su información
                    con nadie excepto como se describe en esta Política de Privacidad.
                    <br/>
                    Los términos utilizados en esta Política de privacidad 
                    tienen el mismo significado que en nuestros
                    {" "}
                    <RouterLink 
                        to={"/terms_and_conditions"}
                    >
                        Términos y Condiciones
                    </RouterLink>
                    {" "}
                    a menos que se defina lo contrario en esta Política de privacidad.
                </Typography>
            </section>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Uso y recolección de información
                </Typography>
                <Typography variant="body1">
                    Para una mejor experiencia mientras utiliza nuestro Servicio, es posible 
                    que le solicitemos que nos proporcione ciertos permisos del dispositivo móvil, entre ellos:
                </Typography>
                <ul>
                    <Typography variant="body1" component="li" className={classes.text}>
                        La ubicación, la cuál no almacenaremos y utilizaremos únicamente con el fin
                        de proporcionar un mejor servicio.
                    </Typography>
                </ul>
                <ul>
                    <Typography variant="body1" component="li" className={classes.text}>
                        El acceso a la cámara y galeria de fotos, para poder realizar u obtener fotos de las recetas médicas correspondientes a 
                        los productos que desea pedir dentro de nuestro servicio, y para poder cambiar su foto de perfil.
                    </Typography>
                </ul>
                <Typography variant="body1">
                    Respecto a la información personal, solicitaremos ciertos datos de identificación
                    personal que incluye, entre otros, el correo electrónico y su nombre. La información que solicitamos 
                </Typography>
            </section>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Acceso a la información por terceros
                </Typography>
                <Typography variant="body1">
                    Parte de la información que utilizamos puede ser conocida por el resto de los
                    farmacéuticos de la aplicación, entre ellas, su nombre, su correo electrónico y su foto
                    incluida en su perfil.
                    Además, podemos compartir la información proporcionada cuando sea requerido
                    por la ley, cuando creamos que es necesario para proteger nuestros derechos,
                    para proteger su seguridad o en respuesta a una solicitud del gobierno.
                    También podemos contratar a terceros debido a las siguientes razones:
                </Typography>
                <ul>
                    <Typography variant="body1" component="li" className={classes.text}>
                        Para facilitar nuestro Servicio;
                    </Typography>
                    <Typography variant="body1" component="li" className={classes.text}>
                        Para proporcionar el Servicio en nuestro nombre;
                    </Typography>
                    <Typography variant="body1" component="li" className={classes.text}>
                        Para realizar servicios relacionados con el servicio provisto; o
                    </Typography>
                    <Typography variant="body1" component="li" className={classes.text}>
                        Para ayudarnos a analizar cómo se utiliza nuestro Servicio.
                    </Typography>
                </ul>
                <Typography variant="body1">
                    Queremos informar a los usuarios de este Servicio que estos terceros
                    tienen acceso a su Información personal. El motivo es realizar las tareas
                    que se les asignen en nuestro nombre. Sin embargo, están obligados a no
                    divulgar ni utilizar la información para ningún otro propósito, además de regirse
                    por las reglas establecidas en esta política de privacidad.
                </Typography>
            </section>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Retención de datos
                </Typography>
                <Typography variant="body1">
                    La información personal brindada será conservada de forma indefinida, en cambio, la información
                    de sus recetas médicas será conservada hasta la finalización de su correspondiente pedido,
                    todo esto para garantizar el uso correcto de nuestro servicio. En caso de que
                    desee eliminar sus datos de nuestro servicio, no dude en enviar un correo electrónico hacia
                    soporte@lomasfarma.com.ar            
                </Typography>
            </section>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Seguridad
                </Typography>
                <Typography variant="body1">
                    Valoramos su confianza al proporcionarnos su información personal,
                    por lo que nos esforzamos por utilizar los medios necesarios
                    para protegerla, pero recuerde que ningún método de transmisión a través
                    de Internet o método de almacenamiento electrónico es 100% seguro y
                    confiable, por lo que no podemos garantizar su seguridad absoluta.
                </Typography>
            </section>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Enlaces a otros sitios
                </Typography>
                <Typography variant="body1">
                    Este servicio puede contener enlaces hacia otros sitios y servicios.
                    Si usted hace clic sobre uno de estos enlaces, usted será dirigido a dicho sitio
                    o servicio. Note que estos sitios y servicios no son operados por nosotros, por
                    lo que aconsejamos que revisen las politicas de privacidad dichos sitios y servicios.
                </Typography>
            </section>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Privacidad de menores
                </Typography>
                <Typography variant="body1">
                    Este Servicio esta dirigido exclusivamente a mayores de 18 años. No recopilamos a
                    sabiendas información de identificación personal de niños menores.
                    En el caso de que descubramos que un niño menor nos ha proporcionado
                    información personal, la eliminamos inmediatamente de nuestros servidores.
                    Si usted es padre o tutor y sabe que su hijo nos ha proporcionado información
                    personal, comuníquese con nosotros para que podamos realizar las acciones
                    necesarias.
                </Typography>
            </section>
            <section className={classes.policySection}>
                <Typography variant="h5" className={classes.title}>
                    Cambios a esta política de privacidad
                </Typography>
                <Typography variant="body1">
                    Es posible que actualicemos nuestra Política de privacidad de vez en cuando.
                    Por lo tanto, le recomendamos que revise esta página periódicamente para
                    ver si hay cambios. Le notificaremos de cualquier cambio publicando la nueva
                    Política de privacidad en esta página.
                    <br/>
                    <br/>
                    Esta política es efectiva a partir del 20/11/2020
                    <br/>
                    Contáctenos si tiene alguna pregunta o sugerencia sobre nuestra política de privacidad, no dude en
                    comunicarse con nosotros a través de un email hacia soporte@lomasfarma.com.ar
                </Typography>
            </section>
        </section>
    )
};

export default PolicyScreen;
