import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Container,
    ButtonBase,
    TextField,
    Button
} from '@material-ui/core';
import useStyles from './styles'
import Carousel from '../../components/carousel/carousel'
import Map from '../../components/map/map'
import { Waypoint } from 'react-waypoint';
import { Element, animateScroll as scroll } from "react-scroll";
import { requestPharmacies } from '../../requesters/pharmacy_requester'
import { requestPromotions } from '../../requesters/promotions_requester'
import { requestSendForm } from '../../requesters/contact_requester'
// Images
import download from '../../assets/header_web.png';
import downloadMobile from '../../assets/header_mobile.png';
import servicio1 from '../../assets/servicio_1.jpg';
import servicio2 from '../../assets/servicio_2.jpg';
import servicio3 from '../../assets/servicio_3.jpg';
import DownloadBanner from '../../components/download_banner/download_banner'

function HomeScreen() {
    const classes = useStyles();
    const [pharmacies, setPharmacies] = useState([]);
    const [promotions, setPromotions] = useState([]);

    // Form
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [errorMail, setErrorMail] = useState("");
    const [stateSent, setStateForm] = useState(0);

    useEffect(() => {
        async function retrieveData() {
            const phs = await requestPharmacies();
            const proms = await requestPromotions();

            if (phs) {
                setPharmacies(phs)
            }

            if (proms) {
                setPromotions(proms);
            }
        }

        retrieveData();
    }, []);

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onSubjectChange = (event) => {
        setSubject(event.target.value);
    }

    const onDescriptionChange = (event) => {
        setDescription(event.target.value);
    }
    
    const formSendEnabled = () => (
        name.length > 0 &&
        email.length > 0 &&
        subject.length > 0 &&
        description.length > 0
    )

    const sendForm = async () => {
        if (formSendEnabled) {
            const [status, response] = await requestSendForm(name, email, subject, description);

            if (status === 201) {
                setName("");
                setEmail("");
                setSubject("");
                setDescription("");
                setErrorMail("");
                setStateForm(1);
            } else if (status === 400) {
                setErrorMail(response["email"][0]);
                setStateForm(0);
            } else {
                setStateForm(2);
            }
        }
    }

    const renderPromotions = () => {
        if (promotions.length === 0) {
            return (
                <Typography variant="h4" className={classes.functionalities}>
                    Por el momento no hay promociones disponibles
                </Typography>
            )
        }
        
        return (
            <Container
                className={classes.carousel}
            >
                <Carousel
                    images={promotions}
                    //className={classes.carousel}
                />
            </Container>
        )
    }

    const renderFormSent = () => {
        if (stateSent === 0) {
            return;
        }

        if (stateSent === 1) {
            return (
                <Typography variant='body1' color="primary">
                    ¡Su consulta ha sido enviada!
                </Typography>
            )
        }

        return (
            <Typography variant='body1' color="error">
                No hemos podido enviar su consulta. Intentelo más tarde
            </Typography>
        )
    }

    return (
        <section>
            <DownloadBanner />       
            <Container
                className={classes.about}
            >
                <section 
                    id={"services"}
                    className={classes.section}
                >
                    <Typography variant="h2" className={classes.functionalities}>
                        Nuestros servicios
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid 
                            item
                            container
                            justify="center"
                            xs={12} sm={6} md={4} lg={4} xl={4}
                        >
                            <img
                                src={servicio1}
                                alt={"Servicios 1"}
                                className={classes.img}
                            />
                        </Grid>
                        <Grid 
                            item
                            container
                            justify="center"
                            xs={12} sm={6} md={4} lg={4} xl={4}
                        >
                            <img
                                src={servicio2}
                                alt={"Servicios 2"}
                                className={classes.img}
                            />
                        </Grid>
                        <Grid 
                            item
                            container
                            justify="center"
                            xs={12} sm={6} md={4} lg={4} xl={4}
                        >
                            <img
                                src={servicio3}
                                alt={"Servicios 3"}
                                className={classes.img}
                            />
                        </Grid>                        
                    </Grid>
                </section>
                <section
                    id={"promotions"}
                    className={classes.section}
                >
                    <Typography variant="h2" className={classes.functionalities}>
                        Nuestras promociones
                    </Typography>
                    {renderPromotions()}
                </section>
                <section
                    id={"pharmacies"}
                    className={classes.section}                
                >
                    <Typography variant="h2" className={classes.functionalities}>
                        Farmacias adheridas
                    </Typography>
                    <Container>
                        <Map
                            markers={pharmacies}
                            className={classes.map}
                        />
                    </Container>
                </section>
                <section
                    id={"contact_us"}
                    className={classes.section}                
                >
                    <Typography variant="h2" className={classes.functionalities}>
                        Contactanos
                    </Typography>
                    <Container
                        className={classes.contactUs}
                    >
                        <TextField
                            id="name"
                            label="Nombre..."
                            variant="outlined"
                            value={name}
                            fullWidth
                            helperText="Ejemplo: Pablo Garcia"
                            className={classes.inputContact}
                            onChange={onNameChange}
                        />
                        <TextField
                            id="email"
                            label="Email..."
                            variant="outlined"
                            value={email}
                            fullWidth
                            helperText="Ejemplo: pablo_garcia@lomasfarma.com.ar"
                            className={classes.inputContact}
                            onChange={onEmailChange}
                            error={errorMail.length > 0}
                            helperText={errorMail}
                        />                        
                        <TextField
                            id="subject"
                            label="Asunto..."
                            variant="outlined"
                            value={subject}
                            fullWidth
                            helperText="Ejemplo: Consulta sobre la app"
                            className={classes.inputContact}
                            onChange={onSubjectChange}
                        />
                        <TextField
                            id="content"
                            label="Descripción..."
                            variant="outlined"
                            value={description}
                            multiline
                            fullWidth
                            helperText="Ejemplo: Tengo una consulta sobre..."
                            rows={6}
                            rowsMax={6}
                            onChange={onDescriptionChange}
                            //className={classes.inputContact}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!formSendEnabled()}
                            onClick={sendForm}
                        >
                            Enviar
                        </Button>
                        {renderFormSent()}
                    </Container> 
                </section>
            </Container>
        </section>
    )
};

export default HomeScreen;
