import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        padding: "32px 32px"
    },
    title: {
        color: theme.palette.primary.main,
        padding: "8px 0"
    },
    termsSection: {
        padding: "8px 0"
    },
    text: {
        fontFamily: 'Raleway'
    }
}));

export default useStyles;
