import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        //paddingRight: "10%",
        //paddingLeft: "10%"
    },
    logo: {
        height: 50,
        width: 50,
        padding: "1%"
    },
    options: {
        fontWeight: 'bold',
        fontSize: "18px",
        padding: "1%",
        color: 'white',
        opacity: 0.5
    },
    highlight: {
        color: "white",
        opacity: 1.0,
        borderBottom: 2
    }
});

export default useStyles;
