import React from 'react';
import logo from '../../assets/logo.png';
import {
    ButtonBase
} from '@material-ui/core';

function Logo(props) {    
    return (
        <ButtonBase
            {...props} disableTouchRipple
        >
            <img
                src={logo}
                alt="logo"
                {...props}
            />
        </ButtonBase>
    );    
}

export default Logo;
