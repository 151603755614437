import React from 'react';
import HomeScreen from './screens/home/home_screen'
import PolicyScreen from './screens/policy/policy_screen'
import TermsScreen from './screens/terms/terms_screen'
import Navigation from "./components/navigation/navigation"
import Footer from './components/footer/footer'
import theme from './theme'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { 
    ThemeProvider
} from '@material-ui/core';
import ScrollToTop from './utils/scrolltotop'

const GoToHome = () => (
    <Redirect to="/" />
)

function App() {
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Router>
                    <ScrollToTop />
                    <Navigation />
                    <Switch>
                        <Route exact path="/policy" component={PolicyScreen} />
                        <Route exact path="/terms_and_conditions" component={TermsScreen} />
                        <Route exact path="/" component={HomeScreen} />
                        <Route path="/" component={GoToHome} />
                    </Switch>
                    <Footer />
                </Router>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default App;
