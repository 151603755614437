import axios from 'axios';
import { URL } from '../config/url'

export async function get(route) {
    try {
        let response = await axios.get(URL + route);

        return response.data;
    } catch {
        return null;
    }
}

export async function post(route, data) {
    try {
        
        let response = await axios.post(URL + route, data);

        return [response.status, response.data];
    } catch (e) {
        let status = e.request.status === 0 ? 500 : e.request.status;
        let response = e.request.response ?
            JSON.parse(e.request.response) : e.request.response;

        return [status, response];
    }    
}