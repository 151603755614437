import { post } from "./basic_requester"

const CONTACT_URL = "/feedback/";

export async function requestSendForm(name, email, subject, description) {
    let form = {
        name: name,
        email: email,
        subject: subject,
        message: description
    }

    return post(CONTACT_URL, form);
}
