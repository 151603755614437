import React from 'react';
import { NavLink, withRouter, useLocation }  from 'react-router-dom'
import {
    AppBar,
    Grid,
    Button,
    ButtonBase,
    Toolbar
} from '@material-ui/core';
import Logo from '../logo/logo'
import useStyles from './styles'
import { Link, animateScroll as scroll } from "react-scroll";

function Navigation() {
    const classes = useStyles();
    const location = useLocation();

    /*const highlight = {
        color: "white",
        opacity: 1.0
    }*/

    return (
        <div>
        <AppBar position="fixed" color="primary">
        <Toolbar className={classes.toolbar}>
            <Logo
                className={classes.logo}
                component={NavLink}
                to="/"
            />
            {location.pathname == "/" &&
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
            >
                <ButtonBase
                    color="inherit"
                    className={classes.options}
                    component={Link}
                    //activeStyle={highlight}
                    //to="/"
                    //exact
                    activeClass={classes.highlight}
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Inicio
                </ButtonBase>
                <ButtonBase
                    color="inherit"
                    className={classes.options}
                    component={Link}
                    //activeStyle={highlight}
                    //exact
                    //to="/promotions"
                    activeClass={classes.highlight}
                    to="promotions"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Promociones
                </ButtonBase>
                <ButtonBase
                    color="inherit"
                    className={classes.options}
                    component={Link}
                    //activeStyle={highlight}
                    //exact
                    //to="/pharmacies"
                    activeClass={classes.highlight}
                    to="pharmacies"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Farmacias
                </ButtonBase>
                <ButtonBase
                    color="inherit"
                    className={classes.options}
                    component={Link}
                    //activeStyle={highlight}
                    //exact
                    //to="/contact_us"
                    activeClass={classes.highlight}
                    to="contact_us"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Contacto
                </ButtonBase>
            </Grid>
            }
        </Toolbar>
        </AppBar>
        <Toolbar />
        </div>
    );    
}

Navigation = withRouter(Navigation);
export default Navigation;
