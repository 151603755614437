import React from 'react';
import {
    Typography,
    Button,
} from '@material-ui/core';
import useStyles from './styles'
// Images
import download from '../../assets/header_web.png';
import downloadMobile from '../../assets/header_mobile.png';
import iosButton from '../../assets/app_store.svg'
import googleButton from '../../assets/google_store.png'

function DownloadBanner() {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <img
                src={download}
                alt={"Banner de descarga"}
                className={classes.download}
            />
            <img
                src={downloadMobile}
                alt={"Banner de descarga"}
                className={classes.downloadMobile}
            />
            <div
                className={classes.buttons}
            >
                <div className={classes.buttonContainerAndroid}>
                <a 
                    href='https://play.google.com/store/apps/details?id=com.lomasfarma&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                >
                    <img 
                        alt='Disponible en Google Play'
                        src={googleButton}
                        className={classes.buttonDownloadAndroid}
                    />
                </a>
                </div>
                <div className={classes.buttonContaineriOS}>
                <a
                    href='https://apps.apple.com/ar/app/lomas-farma/id1538491027?itsct=apps_box_link&itscg=30200'
                >
                    <img
                        alt='Descargalo en el App Store'
                        src={iosButton}
                        className={classes.buttonDownloadiOS}
                    />
                </a>
                </div>
            </div>
        </div>
    )
}

export default DownloadBanner;
