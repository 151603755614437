import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    carousel: {
        //paddingTop: 10,
        //maxWidth: "60vw"
        width: "100%",
        maxWidth: "800px",
    },
    download: {
        //height: "100%",
        //display: 'block',
        overflow: 'hidden',
        width: '100%',
        minHeight: "200px",
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    downloadMobile: {
        overflow: 'hidden',
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    img: {
        //height: "100%",
        display: 'block',
        overflow: 'hidden',
        width: '90%',
        borderRadius: "24px",
    },
    about: {
        //paddingRight: "5%",
        //paddingLeft: "5%"
        overflow: 'hidden',
        //padding: "32px 32px"
        //paddingTop: 32,
        //paddingBottom: 32,
    },
    functionalities: {
        textAlign: 'center',
        paddingBottom: "32px"
    },
    map: {
        height: "70vh",
        maxHeight: "800",
        width: "100%"
    },
    paddings: {
        backgroundColor: 'red'
    },
    divider: {
        padding: "5%"
    },
    inputContact: {
        marginBottom: "16px"
    },
    section: {
        padding: "16px 0px"
    },
    contactUs: {
        maxWidth: "800px",
        textAlign: 'center'
    }
}));

export default useStyles;
