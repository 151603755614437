const fields = {
    Location: "location"
}

export function pharmacyParser(json) {
    let parsed_pharmacy = []

    for (let pharmacy of json) {
        let split = pharmacy[fields['Location']].split(/\s+/);

        pharmacy[fields['Location']] = {
            latitude: parseFloat(split[2].replace(')','')),
            longitude: parseFloat(split[1].replace('(',''))
        }

        parsed_pharmacy.push(pharmacy);
    }

    return parsed_pharmacy;
}
