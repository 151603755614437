import React, { useState, useEffect } from 'react';
import {
    Typography,
    Link
} from '@material-ui/core';
import useStyles from './styles'
import { Link as RouterLink, withRouter }  from 'react-router-dom'

function TermsScreen() {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <section className={classes.termsSection}>
                <Typography variant="h5" className={classes.title}>
                    Términos y Condiciones
                </Typography>
                <Typography variant="body1">
                    Al descargar o usar la aplicación, estos términos se aplicarán
                    automáticamente a usted; por lo tanto, debe asegurarse de leerlos
                    detenidamente antes de usar la aplicación. No se le permite copiar
                    ni modificar la aplicación, ninguna parte de la aplicación o nuestras
                    marcas comerciales de ninguna manera. No tiene permitido intentar
                    extraer el código fuente de la aplicación y tampoco debe intentar
                    traducir la aplicación a otros idiomas o hacer versiones derivadas.
                    La aplicación en sí, y todas las marcas, derechos de autor, derechos
                    de bases de datos y otros derechos de propiedad intelectual relacionados
                    con ella, siguen perteneciendo al Colegio de Farmacéuticos de Lomas de Zamora.
                    <br/>
                    El Colegio de Farmacéuticos de Lomas de Zamora se compromete a garantizar
                    que la aplicación sea lo más útil y eficiente posible. Por ese motivo,
                    nos reservamos el derecho de realizar cambios en la aplicación o cobrar
                    por sus servicios, en cualquier momento y por cualquier motivo. Nunca le
                    cobraremos por la aplicación o sus servicios sin dejarle muy claro
                    exactamente por qué está pagando.
                    <br/>
                    La aplicación Lomas Farma almacena y procesa los datos personales que nos
                    ha proporcionado para poder brindarle nuestro servicio. Es su
                    responsabilidad mantener su teléfono y el acceso a la aplicación
                    seguros. Por lo tanto, le recomendamos que no rootee
                    su teléfono, que es el proceso de eliminar las restricciones y
                    limitaciones de software impuestas por el sistema operativo oficial
                    de su dispositivo, podría hacer que su teléfono sea vulnerable a malware
                    / virus / programas maliciosos, comprometer las funciones de seguridad
                    de su teléfono y podría significar que la aplicación Lomas Farma no
                    funcionará correctamente o no funcionará en absoluto.
                </Typography>
            </section>
            <section className={classes.termsSection}>
                <Typography variant="h5" className={classes.title}>
                    Servicios de terceros
                </Typography>
                <Typography variant="body1">
                    La aplicación utiliza servicios de terceros que declaran
                    sus propios términos y condiciones.
                    Enlaces a los términos y condiciones de los
                    proveedores de servicios externos utilizados por la aplicación
                </Typography>    
                <ul>
                    <Typography variant="body1" component="li" className={classes.text}>
                        <Link
                            href="https://policies.google.com/terms"
                        >
                            Google Play Services
                        </Link>
                    </Typography>
                </ul>
            </section>
            <section className={classes.termsSection}>
                <Typography variant="h5" className={classes.title}>
                    Sobre uso de datos y de la aplicación
                </Typography>
                <Typography variant="body1">
                    Si está utilizando la aplicación fuera de un área con Wi-Fi, debe
                    recordar que los términos del acuerdo con su proveedor de red móvil          
                    aún se aplicarán. Como resultado, su proveedor de telefonía móvil puede
                    cobrarle el costo de los datos durante la duración de la conexión mientras
                    accede a la aplicación, u otros cargos de terceros. Al usar la aplicación,
                    acepta la responsabilidad de dichos cargos, incluidos los cargos de datos
                    de roaming si usa la aplicación fuera de su territorio de origen (es decir,
                    región o país) sin desactivar el roaming de datos. Si usted no es el responsable
                    de las facturas del dispositivo en el que está usando la aplicación, tenga en
                    cuenta que asumimos que recibió el permiso del responsable para
                    usar la aplicación.
                    <br/>
                    Sobre la misma línea, el Colegio de Farmacéuticos de Lomas de Zamora
                    no siempre puede asumir la responsabilidad sobre la forma en que usa
                    la aplicación, es decir, debe asegurarse de que su dispositivo
                    permanezca cargado, si se queda sin batería y no puede encenderlo
                    para hacer uso del Servicio, el Colegio de Farmacéuticos de Lomas
                    de Zamora no se hace responsable.
                    <br/>
                    Con respecto a la responsabilidad del Colegio de Farmacéuticos de
                    Lomas de Zamora por el uso que usted hace de la aplicación, cuando usted
                    está usando la aplicación, es importante tener en cuenta que aunque nos
                    esforzemos por asegurarnos de que esté actualizada y sea correcta en todo
                    momento, confiamos en que terceros nos proporcionen información para que
                    podamos ponerla a su disposición. El Colegio de Farmacéuticos de Lomas de 
                    Zamora no se hace responsable de ninguna pérdida, directa o indirecta, que
                    usted experimente como resultado de confiar totalmente en esta funcionalidad
                    de la aplicación.
                </Typography>
            </section>
            <section className={classes.termsSection}>
                <Typography variant="h5" className={classes.title}>
                    Sobre actualizaciones
                </Typography>
                <Typography variant="body1">
                    En algún momento, tal vez queramos actualizar la aplicación.
                    La aplicación está actualmente disponible en Android e iOS -
                    los requisitos para ambos sistemas (y para cualquier sistema adicional
                    al que decidamos extender la disponibilidad de la aplicación) pueden
                    cambiar, y tendrá que descargar las actualizaciones si quiere seguir
                    usando la aplicación. El Colegio de Farmacéuticos de Lomas de Zamora
                    no promete que siempre actualizará la aplicación para que sea relevante
                    para usted y/o funcione con la versión de Android e iOS que tenga
                    instalada en su dispositivo. Sin embargo, promete aceptar siempre
                    las actualizaciones de la aplicación cuando se le ofrezcan. También
                    es posible que deseemos dejar de ofrecer la aplicación, y podemos
                    terminar el uso de la misma en cualquier momento sin avisarle de la
                    terminación. A menos que le indiquemos lo contrario, al producirse
                    cualquier cancelación, (a) los derechos y licencias que se le conceden
                    en estos términos terminarán; (b) deberá dejar de utilizar la aplicación
                    y (si es necesario) eliminarla de su dispositivo.       
                </Typography>
            </section>
            <section className={classes.termsSection}>
                <Typography variant="h5" className={classes.title}>
                    Cambios a nuestros términos y condiciones
                </Typography>
                <Typography variant="body1">
                    Podemos actualizar nuestros Términos y Condiciones de vez en
                    cuando. Por lo tanto, se le aconseja que revise esta página
                    periódicamente para conocer los cambios. Le notificaremos
                    cualquier cambio publicando los nuevos Términos y Condiciones
                    en esta página.
                    <br/>
                    <br/>
                    Estos términos y condiciones son efectivos a partir del 20/11/2020
                    <br/>
                    Contáctenos si tiene alguna pregunta o sugerencia sobre nuestros Términos y condiciones, no dude en
                    comunicarse con nosotros a través de un email hacia soporte@lomasfarma.com.ar
                </Typography>
            </section>
        </section>
    )
};

export default TermsScreen;
