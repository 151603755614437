import React, { useState } from 'react';
import {
    MobileStepper,
    Button,
    Grid,
    Container
} from '@material-ui/core';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight
} from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import useStyles from './styles'
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Carousel(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [activeStep, setActiveStep] = useState(0);

    const maxSteps = props.images.length;
  
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.container}
            >
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    className={classes.buttonLeft}
                >
                    {theme.direction === 'rtl' ?
                    <KeyboardArrowRight
                        fontSize="large"
                    /> :
                    <KeyboardArrowLeft
                        fontSize="large"
                    />
                    }
                </Button>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    className={classes.autoplay}
                >
                    {props.images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                        <img className={classNames(classes.img, props.className)} src={step.image} alt={step.name} />
                        ) : null}
                    </div>
                    ))}
                </AutoPlaySwipeableViews>
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    className={classes.buttonRight}
                >
                    {theme.direction === 'rtl' ?
                    <KeyboardArrowLeft
                        fontSize="large"
                    /> :
                    <KeyboardArrowRight
                        fontSize="large"
                    />
                    }
                </Button>
            </Grid>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                className={classes.stepper}
            />
        </div>
    );
};

Carousel.propTypes = {
    images: PropTypes.array.isRequired,
}

export default Carousel;
