import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        //maxWidth: "50vw"
    },
    img: {
        display: 'block',
        overflow: 'hidden',
        width: "100%",
        alignSelf: 'center'
    },
    autoplay: {
        //maxWidth: "60vw",
    },
    container: {
        position: "relative"
    },
    stepper: {
        backgroundColor: 'white',
        justifyContent: 'center'
    },
    buttonLeft: {
        position: 'absolute',
        left: -40,
        zIndex: 99
    },
    buttonRight: {
        position: 'absolute',
        right: -40,
        zIndex: 99
    }
});

export default useStyles;
