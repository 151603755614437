import { makeStyles } from '@material-ui/core/styles';
import { ScaleControl } from 'react-leaflet';

const useStyles = makeStyles(theme => ({
    footer: {
        /*top: 'auto',
        bottom: 0,*/
        padding: 8,
        backgroundColor: theme.palette.primary.main
    },
    toolbar: {
        //justifyContent: 'center'
    },
    icon: {
        padding: 8,
        color: "white",
        '&:hover': {
            transform: "scale(1.1)",
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subContainer: {
        alignItems: 'flex-start',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',        
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        borderStyle: 'dotted',
        //width: "100%",
        //overflow: 'hidden'
        //alignItems: 'center',
        //justifyContent: 'center',
    },
    columns: {
        //borderStyle: 'dotted'
    },
    text: {
        wordWrap: "break-word",
        textAlign: 'left',
        color: 'lightgray',
        textDecoration: 'none',
        fontFamily: 'Raleway'
        //width: "100%"
    },
    divider: {
        borderStyle: 'solid',
        borderWidth: "1px",
        width: "50%",
        margin: "16px",
        color: "white"
    },
    centeredText: {
        color: "white"
    }
}));

export default useStyles;
