import React from 'react';
import { Link as RouterLink, withRouter }  from 'react-router-dom'
import {
    AppBar,
    Grid,
    Button,
    ButtonBase,
    Box,
    Typography,
    Toolbar,
    Container,
    Divider,
    Link
} from '@material-ui/core';
import Logo from '../logo/logo'
import useStyles from './styles'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

function Footer() {
    const classes = useStyles();

    return (
        <footer
            className={classes.footer}
        >
                <Container className={classes.container}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        id={"services"}
                        className={classes.subContainer}
                        //spacing={2}
                    >
                        <Grid
                            item
                            xs={12} sm={5} md={4} lg={4} xl={4}
                            container
                            justify="center"
                            direction="column"
                            className={classes.columns}
                        >
                                <Typography
                                    variant="h6"
                                    className={classes.centeredText}
                                >
                                    Contacto
                                </Typography>
                                <Link 
                                    className={classes.text}
                                    href="mailto:soporte@lomasfarma.com.ar"
                                >
                                    soporte@lomasfarma.com.ar
                                </Link>
                                <Typography className={classes.text}>
                                    011 4245-4397
                                </Typography>
                                <Typography className={classes.text}>
                                    Monseñor Piaggio 553
                                </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12} sm={5} md={4} lg={4} xl={4}
                            container
                            justify="center"
                            direction="column"
                            className={classes.columns}
                        >
                                <Typography
                                    variant="h6"
                                    className={classes.centeredText}
                                >
                                    Legales
                                </Typography>
                                <RouterLink
                                    className={classes.text}
                                    to={"/policy"}
                                >
                                    Política de privacidad
                                </RouterLink>
                                <RouterLink
                                    className={classes.text}
                                    to={"/terms_and_conditions"}
                                >
                                    Términos y condiciones
                                </RouterLink>
                        </Grid>                        
                    </Grid>
                    <div className={classes.divider} />
                    <Typography variant="h6" className={classes.centeredText}>
                        Redes sociales
                    </Typography>
                    <Container className={classes.iconContainer}>
                        <Link 
                            href="https://www.instagram.com/lomasfarmapp/"
                        >
                            <InstagramIcon 
                                className={classes.icon}
                                fontSize={"large"}
                            />
                        </Link>
                        <Link 
                            href="https://www.facebook.com/lomasfarmapp/"
                        >
                            <FacebookIcon
                                className={classes.icon}
                                fontSize={"large"} 
                            />
                        </Link>
                    </Container>
                </Container>
        </footer>
    );
}

export default Footer;
