import { makeStyles } from '@material-ui/core/styles';
import { ScaleControl } from 'react-leaflet';

const useStyles = makeStyles(theme => ({
    container: {
        position: "relative"
    },
    download: {
        overflow: 'hidden',
        width: '100%',
        minHeight: "200px",
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    downloadMobile: {
        overflow: 'hidden',
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    buttons: {
        [theme.breakpoints.down('xs')]: {
            margin: "auto",
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            right: "5%",
            bottom: "35%",
            alignItems: 'center',
            justifyContent: 'flex-end',
            display: 'flex',
            width: "30%"
        }
    },
    buttonContainerAndroid: {
        flex: 1,
    },
    buttonContaineriOS: {
        flex: 1,
    },
    buttonDownloadAndroid: {
        width: "100%"
    },
    buttonDownloadiOS: {
        width: "80%"
    }
}));

export default useStyles;
