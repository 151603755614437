import { get } from "./basic_requester"
import { pharmacyParser } from '../utils/json_formatter'

const PHARMACY_URL = "/pharmacies/";

export async function requestPharmacies() {
    let pharmacies = await get(PHARMACY_URL);

    if (pharmacies === null) {
        return null;
    }

    return pharmacyParser(pharmacies);
}
